// ModalContext.js
"use client";

import React, { createContext, useState, useContext } from "react";

export const ModalContext = createContext();

export default function useModal() {
  return useContext(ModalContext);
}
