"use client";

import useHideModal from "@/functions/useHideModal";
import { useRef } from "react";
import { twMerge } from "tailwind-merge";

export default function Dropdown({
  children,
  className,
  show = false,
  hide = () => {},
}) {
  const wrapper = useRef(null);
  useHideModal(wrapper, () => show && hide());
  return (
    show && (
      <div
        ref={wrapper}
        onMouseLeave={() => hide()}
        className={twMerge("absolute", className)}>
        {children}
      </div>
    )
  );
}
