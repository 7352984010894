"use client";

import { cn } from "@/lib/utils";
import React, { useEffect, useState } from "react";

export default function UpBtn() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 1000) {
        setIsVisible(true); // Scrolling up
      } else {
        setIsVisible(false); // Scrolling down
      };
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible]);

  return (
    <div
      onClick={() => isVisible && window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })}
      className={cn("fixed max-h-screen bottom-3 xs:bottom-6 md:bottom-12 right-3 xs:right-6 md:right-12 z-[100] transition-opacity delay-75 opacity-0", isVisible && "hover:shadow-mediumGON cursor-pointer opacity-100", "xs:flex hidden")}
    >
      <div className="h-10 w-10 items-center flex justify-center rounded-full bg-primaryGON-400 -rotate-90  text-primaryGON-50">
      &#10142;
      </div>
    </div>
  );
}
