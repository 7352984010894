"use client";

import { listSosmed, listActionCek } from "@/functions/dinamisData";
import { useCheckBreakPoint } from "@/functions/usResponsive";
import useHeader from "@/functions/useHeader";
import { cn } from "@/utils/twMerge";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export default function MiniHeader() {
  const pathname = usePathname();
  const { session, status } = useHeader();
  const router = useRouter();
  const dataListSosmed = listSosmed({});
  const listTextAction = listActionCek();
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (pathname?.includes("member-area")) return;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < lastScrollY) {
        setIsVisible(true); // Scrolling up
      } else {
        setIsVisible(false); // Scrolling down
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [session?.user?.token, lastScrollY]);

  useEffect(() => {
    if (!session?.user?.token) return;
    const handleScroll = () => {
      const featureTour = document.getElementById("feature-tour");
      const scrolled = window.scrollY;

      if (!featureTour) return;
      if (scrolled >= 1) {
        featureTour.style.display = "none";
      } else {
        featureTour.style.display = "flex";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // console.log("Removing scroll event listener");
      window.removeEventListener("scroll", handleScroll);
    };
  }, [session?.user?.token]);

  let breakpoint = useCheckBreakPoint();

  return (
    <div
      className={cn(
        "transition-all duration-300 ",
        isVisible ? "opacity-100 visible" : "xs:opacity-0 xs:hidden xs:h-0"
      )}>
      <div
        className={cn(
          "flex w-full justify-between bg-primaryGON-50 py-1 px-gon items-center overflow-hidden"
        )}>
        <div className='flex gap-2 items-center'>
          {dataListSosmed?.map((item, index) => {
            const Icon = item?.icon;
            return (
              <Link
                key={index}
                as={item?.url ?? "/"}
                aria-label={item?.value}
                shallow
                passHref
                href={item?.url ?? "/"}>
                <Icon className='h-8 w-8 text-primaryGON-400' />
              </Link>
            );
          })}
        </div>
        <div className='flex gap-6 items-center'>
          {listTextAction?.map((item, index) => {
            let url =
              session?.user?.data?.mitra_level > 4 && // khusus end user karena path diganti menjadi daftar-transaksi (dummyLayout, Sidebar)
              item?.title == "Cek Status Order"
                ? "/member-area/daftar-transaksi"
                : item?.url;
            return breakpoint == "ui-phone" &&
              item?.title == "Cek Status Order" ? null : (
              <Link
                className={"hover:underline hover:underline-offset-1"}
                key={index}
                aria-label={item?.title}
                as={
                  item?.title == "Cek Status Order" && !session?.user?.token
                    ? "/signin"
                    : url ?? "/"
                }
                shallow
                passHref
                href={
                  item?.title == "Cek Status Order" && !session?.user?.token
                    ? "/signin"
                    : url ?? "/"
                }>
                <span
                  className={cn(
                    "whitespace-nowrap truncate font-work-sans font-medium text-xs text-primaryGON-400 "
                  )}>
                  {item?.title}
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
