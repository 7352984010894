// ModalContext.js
"use client";

import { createContext, useContext, useState } from "react";
import { IconGonSvg, LoadingSvg } from "@/public/svg";
import { twMerge } from "tailwind-merge";

export default function Loading({ className, versi = 2 }) {
  return (
    <div
      className={twMerge(
        "flex flex-col h-screen justify-center items-center ",
        className
      )}>
      {versi == 1 ? (
        <div className='relative p-4 text-primaryGON-400 rounded-full'>
          <div className='h-full w-full bg-white z-10 absolute load-animation' />
          <IconGonSvg className='w-12 h-12  xl:w-20 xl:h-20' />
        </div>
      ) : (
        <div className='relative p-4 text-primaryGON-400 rounded-full gap-4 flex flex-col justify-center items-center'>
          <div className='animate-spin rounded-full h-5 w-5 border-t-4 border-primaryGON-400'></div>
          <p className='capitalize p1-medium text-primaryGON-400'>Loading...</p>
        </div>
      )}
    </div>
  );
}

export const LoadingContext = createContext();

export function useLoading() {
  return useContext(LoadingContext);
}

export function LoadingFullProvider({ children }) {
  const [className, setClassName] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [desc, setDesc] = useState("Menunggu order dibuat...");

  function showLoad() {
    setShowLoading(true);
  }

  function hideLoad() {
    setShowLoading(false);
  }
  return (
    <LoadingContext.Provider
      value={{ setClassName, showLoad, hideLoad, setDesc }}>
      {showLoading && (
        <div className='fixed inset-0 flex items-center justify-center z-[100] bg-shadesGON-50'>
          <div
            className={twMerge(
              "flex flex-col h-screen justify-center items-center bg-shadesGON-50",
              className
            )}>
            <div className='relative px-4 mb-4 text-primaryGON-400 rounded-full'>
              <div className='h-full w-full bg-white z-10 absolute load-animation' />
              <IconGonSvg className='w-12 h-12  xl:w-20 xl:h-20' />
            </div>
            <p className='text-primaryGON-400 text-center capitalize text-button-small load-animation-opacity'>
              {desc}
            </p>
          </div>
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
}
