import AlertCard, { AlertCardV2 } from "@/components/atom/Alert-Card";
import { AlertContext } from "@/functions/useAlert";
import { createContext, useCallback, useContext, useState } from "react";
import { twMerge } from "tailwind-merge";

function AlertComponent({
  visible = false,
  setMyProps,
  versiAlert = 1,
  error = false,
  colors = "primary",
  title = "Text Here",
  desc = "Desc Here",
  descBold = "",
  handleClick = {
    open: {
      onClick: () => {},
      text: "Open Alert",
      disable: false,
      hide: false,
      colors: ""
    },
    close: {
      onClick: () => {},
      text: "Close Alert",
      disable: false,
      hide: false,
      colors: ""
    }
  },
  defaultButton = false
}) {
  return (
    visible && (
      <div
        onClick={() => setMyProps({ visible: false })}
        className="fixed inset-0 flex items-center justify-center bg-shadesGON-100 bg-opacity-50"
        style={{
          zIndex: 101
        }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={twMerge(" bg-shadesGON-50  rounded-lg shadow-largeGON")}
        >
          {versiAlert == 1 ? (
            <AlertCard
              error={error}
              colors={colors}
              descBold={descBold}
              desc={desc}
              title={title}
              defaultButton={defaultButton}
              handleClick={handleClick}
            />
          ) : (
            <AlertCardV2
              error={error}
              colors={colors}
              descBold={descBold}
              desc={desc}
              title={title}
              defaultButton={defaultButton}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    )
  );
}

// 1. create context
// 2. create provider

export default function AlertProvider(props) {
  const [myProps, setMyProps] = useState({
    visible: false,
    versiAlert: 1,
    error: false,
    colors: "primary",
    title: "Text Here",
    desc: "Desc Here",
    descBold: "",
    handleClick: {
      open: {
        onClick: () => {},
        text: "Open Alert",
        disable: false,
        hide: false,
        colors: ""
      },
      close: {
        onClick: () => {},
        text: "Close Alert",
        disable: false,
        hide: false,
        colors: ""
      }
    },
    defaultButton: false
  });

  const showAlert = useCallback(
    ({
      colors,
      versiAlert,
      error,
      title,
      desc,
      descBold,
      handleClick = {
        close: {
          onClick: () => setMyProps({ ...myProps, visible: false }),
          text: "Close",
          disable: false,
          hide: false,
          colors: ""
        }
      },
      defaultButton = false
    }) => {
      setMyProps({
        visible: true,
        versiAlert: versiAlert,
        error: error,
        colors: colors,
        title: title,
        desc: desc,
        descBold: descBold,
        handleClick: handleClick,
        defaultButton: defaultButton
      });
    },
    [myProps]
  );

  const newHandleClick = {
    open: {
      onClick: () => {
        myProps?.handleClick?.open?.onClick();
        setMyProps({ visible: false });
      },
      text: myProps?.handleClick?.open?.text,
      disable: myProps?.handleClick?.open?.disable,
      hide: myProps?.handleClick?.open?.hide,
      colors: myProps?.handleClick?.open?.colors
    },
    close: {
      onClick: () => {
        myProps?.handleClick?.close?.onClick();
        setMyProps({ visible: false });
      },
      text: myProps?.handleClick?.close?.text,
      disable: myProps?.handleClick?.close?.disable,
      hide: myProps?.handleClick?.close?.hide,
      colors: myProps?.handleClick?.close?.colors
    }
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {props.children}
      <AlertComponent
        visible={myProps?.visible}
        versiAlert={myProps?.versiAlert}
        error={myProps?.error}
        colors={myProps?.colors}
        descBold={myProps?.descBold}
        desc={myProps?.desc}
        title={myProps?.title}
        defaultButton={myProps?.defaultButton}
        handleClick={newHandleClick}
        setMyProps={setMyProps}
      />
    </AlertContext.Provider>
  );
}
