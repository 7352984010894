'use client'
import { AppProgressBar} from "next-nprogress-bar";


const applySearchParamsWorkaround = (url) => {
  const { location } = window
  const isSamePage = url.pathname === location.pathname
  const paramsChanged = url.search !== location.search
  if (isSamePage && paramsChanged) {
      url.pathname = `force_reload_${Math.random()}`
  }

  return url
}

export default function ProgressBar() {
  return (
    <AppProgressBar
      height="4px"
      color="#5A25B0"
      options={{ showSpinner: false }}
      targetPreprocessor={applySearchParamsWorkaround}
      shallowRouting
    />
  );
}
