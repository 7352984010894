export default function checkLevel(item) {
  return item == 1
    ? "Distributor"
    : item == 2
    ? "Nibras House"
    : item == 3
    ? "Agen"
    : item == 4
    ? "Reseller"
    : "End User";
}
