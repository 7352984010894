"use client";

import { WarningSolidSvg } from "@/public/svg";
import AlertGon from "../sub-atom/Alert";
import Button from "./Button";

export default function AlertCard({
  colors = "primary",
  error = false,
  title = "Text Here",
  desc = "Desc Here",
  descBold = "",
  handleClick = {
    open: {
      onClick: () => {},
      text: "Button Text",
      disable: false,
      hide: false,
      colors: "primary",
    },
    close: {
      onClick: () => {},
      text: "Button Text",
      disable: false,
      hide: false,
      colors: "primary",
    },
  },
}) {
  colors = error ? "error" : colors;
  return (
    <div className='max-w-lg flex flex-col gap-8 p-8 text-center'>
      <AlertGon
        title={title}
        colors={colors}
      />
      <div>
        <p className='p1-regular text-center text-greyscaleGON-500 md:px-3'>
          <strong>{descBold} </strong>
          {desc}
        </p>
      </div>
      <div className='flex w-full flex-col gap-4'>
        {!handleClick?.open?.hide && (
          <Button
            colors={handleClick?.open?.colors}
            label={handleClick?.open?.text}
            onClick={handleClick?.open?.onClick}
            disable={handleClick?.open?.disable}
          />
        )}
        {!handleClick?.close?.hide && (
          <Button
            colors={handleClick?.close?.colors}
            variants='outline'
            label={handleClick?.close?.text}
            onClick={handleClick?.close?.onClick}
            disable={handleClick?.close?.disable}
          />
        )}
      </div>
    </div>
  );
}

export function AlertCardV2({
  colors = "primary",
  error = false,
  title = "Text Here",
  desc = "Desc Here",
  descBold = "",
  handleClick = {
    open: {
      onClick: () => {},
      text: "Button Text",
      disable: false,
      hide: false,
      colors: "primary",
    },
    close: {
      onClick: () => {},
      text: "Button Text",
      disable: false,
      hide: false,
      colors: "primary",
    },
  },
}) {
  colors = error ? "error" : colors;
  const colorsIcon = error ? "error" : "warning";
  return (
    <div className='max-w-lg flex flex-col gap-6 p-8 text-center'>
      <div className='flex justify-center items-center'>
        <div className={`p-4 rounded-full bg-${colorsIcon}GON-50`}>
          <WarningSolidSvg className={`h-6 w-6 text-${colorsIcon}GON-400`} />
        </div>
      </div>
      <p className='subheading-semiBold text-greyscaleGON-500'>{title}</p>
      <div>
        <p className='p1-regular text-greyscaleGON-500 md:px-3 text-justify'>
          {typeof desc == "function" ? desc() : desc}
        </p>
      </div>
      <div className='flex w-full flex-col gap-4'>
        {!handleClick?.open?.hide && (
          <Button
            colors={handleClick?.open?.colors}
            label={handleClick?.open?.text}
            onClick={handleClick?.open?.onClick}
            disable={handleClick?.open?.disable}
          />
        )}
        {!handleClick?.close?.hide && (
          <Button
            colors={handleClick?.close?.colors}
            variants='outline'
            label={handleClick?.close?.text}
            onClick={handleClick?.close?.onClick}
            disable={handleClick?.close?.disable}
          />
        )}
      </div>
    </div>
  );
}
